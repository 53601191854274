import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from "./api.service";
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiService: ApiService, private authService: AuthService) {
  }

  public header: any = {
    Authorization: this.authService.getToken,
  }

  getUsers(page: number = 1, limit: number = 10): Observable<any> {
    return this.apiService.httpGetMyAccount(`user/get-users?page=${page}&limit=${limit}`, this.header);
  }

  getUnverifiedUsers(page: number = 1, limit: number = 10): Observable<any> {
    return this.apiService.httpGetMyAccount(`user/get-unverified-users?page=${page}&limit=${limit}`, this.header);
  }

  verifyUser(userId: number): Observable<any> {
    return this.apiService.httpPostMyAccount(`user/verify-user`, {user_id: userId}, this.header);
  }

  getUser() {
    return this.apiService.httpGetMyAccount('user/get-user', this.header);
  }

}
