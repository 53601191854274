import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../shared/services/api.service";
import {CookieService} from "ngx-cookie-service";
import {NgxSpinnerService} from "ngx-spinner";
import { environment } from '../../../environments/environment';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';
import { dropDownWorkplace } from '../../shared/models/static-data.model';


@Component({
  selector: 'app-professional-details',
  templateUrl: './professional-details.component.html',
  styleUrl: './professional-details.component.scss'
})
export class ProfessionalDetailsComponent {

  public countries: any = [];
  public user = localStorage.getItem('user') || '';
  public showStudentField: boolean = false;
  public workplaces = dropDownWorkplace
  professionalDetailForm: FormGroup;
  success_card: boolean = false;

  currentYear: number = new Date().getFullYear();
  maxYear: number = this.currentYear + 10
  public loader: boolean = false

  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private cookieService: CookieService, private spinner: NgxSpinnerService, private googleAnalytics: GoogleAnalyticsService) {
    this.professionalDetailForm = this.formBuilder.group({
      workplace: ['', Validators.required],
      work_address: ['', Validators.required],
      university: ['', Validators.required],
      uni_country: ['', Validators.required],
      year_qualification: ['', [
        Validators.required,
        Validators.min(this.currentYear),
        Validators.max(this.maxYear)
      ]]
    });

    // Listen for changes on the workplace control
    this.professionalDetailForm.get('workplace')?.valueChanges.subscribe(value => {
      this.updateWorkAddressValidators(value);
    });
  }

  trackButtonClick(btn_name: any) {
    this.professionalDetailForm.markAllAsTouched()

    if (this.professionalDetailForm.invalid) return

    this.googleAnalytics.trackButtonClick({'button': btn_name, 'payload': this.formValue(), page: 'Email subscription'});
  }

  updateWorkAddressValidators(value: string): void {
    const workAddressControl = this.professionalDetailForm.get('work_address');
    if (value === 'lo') {
      workAddressControl?.clearValidators();
    } else {
      workAddressControl?.setValidators([Validators.required]);
    }
    workAddressControl?.updateValueAndValidity();
  }

  ngOnInit(): void {
    const user: any = JSON.parse(this.user);
    this.updateValidators(user.profile.job_role);
    const country_name: any = user.profile ? user.profile.country_of_residence : user.country_of_residence;
    this.getCountries(country_name);
  }

  updateValidators(job_role: string) {
    const yearQualificationControl: any = this.professionalDetailForm.get('year_qualification');
    const uniCountryControl: any = this.professionalDetailForm.get('uni_country');
    const countryControl: any = this.professionalDetailForm.get('university');

    if (job_role === 'S' || job_role === 'G') {

      yearQualificationControl.setValidators([
        Validators.required,
        Validators.min(this.currentYear),
        Validators.max(this.maxYear)
      ]);
      uniCountryControl.setValidators([Validators.required]);
      countryControl.setValidators([Validators.required]);
      this.showStudentField = true;

    } else {

      yearQualificationControl.clearValidators();
      uniCountryControl.clearValidators();
      countryControl.clearValidators();
      this.showStudentField = false;

    }

    yearQualificationControl.updateValueAndValidity();
    uniCountryControl.updateValueAndValidity();
    countryControl.updateValueAndValidity();
  }

  formValue() {
    // Manually filter out hidden fields
    const value = this.professionalDetailForm.value;
    if (!this.showStudentField) {
      delete value.university;
      delete value.uni_country;
      delete value.year_qualification;
    }
    return value;
  }

  saveProfessionalDetails(e: Event) {

    e.preventDefault();
    this.professionalDetailForm.markAllAsTouched()

    if (this.professionalDetailForm.invalid) return

    this.spinner.show()
    this.loader = true;
    this.success_card = false;
    let token = this.cookieService.get('sso_signed')

    this.apiService.httpPostMyAccount('user/update-user', this.formValue(), {Authorization: token}).subscribe((response: any) => {

      this.spinner.hide()
      this.loader = false;
      this.professionalDetailForm.reset();
      this.success_card = true;

    })

  }

  productLink() {
    window.open(environment.myJobsUrl);
  }

  getCountries(country_name: any) {
    this.countries = [];
    this.apiService.httpGetMyAccount('user/countries').subscribe(
      (res: any) => {
        this.countries = res.data;

        const country = this.countries.find((c: any) => c.country_name.toLowerCase() == country_name.toLowerCase())
        this.professionalDetailForm.patchValue({uni_country: country?.country_name});

      },
      (err: any) => {
        console.error('Error fetching countries', err);
      }
    );
  }

  onCountryChange(e: any) {
    this.professionalDetailForm.patchValue({uni_country: e.target.value});
  }

}
