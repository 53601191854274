<div class="card">
  <div class="row mb-md-3 mb-2">
    <div class="col-xl-4 col-lg-3">
      <h3 class="card-title mb-0">Validated accounts</h3>
    </div>
    <div class="col-xl-8 col-lg-9 mt-md-0 mt-3 ">
      <div class="row g-3 align-items-center">
        <div class="col-md-4">
        </div>

        <div class=" col-md-3 ">
        </div>

        <div class="col-md-5">
          <div class="d-flex gap-3">
            <div class="w-100">

              <select class="form-select form-select-sm" [(ngModel)]="validated_status">
              <option value="" selected disabled>Account Type</option>
                <option value="1">Accounts validated</option>
                <option value="0">Accounts not validate</option>
              </select>

            </div>
            <button class="btn btn-sm btn-primary icon-btn text-nowrap" (click)="getData()"><span class="text">Get data</span></button>
          </div>

      </div>

      </div>
    </div>
  </div>


  <Datatable [url]="url" [colDefs]="colDefs" [jsonFilters]="jsonFilters"></Datatable>
</div>
