import { Component } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { EmitterService } from '../../../shared/services/event-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  public name: string = '';
  public menu: boolean = true;

  constructor(private authService: AuthService, private eventEmitter: EmitterService) {

    if (this.authService.currentUserValue) {
      let profile = this.authService.currentUserValue.profile
      this.name = profile.name + ' ' + profile.last_name;
    }

  }

  handleClick(e: any) {
    e.preventDefault();
    this.menu = !this.menu
    this.eventEmitter.emit('sidebar', this.menu)
  }

  logout() {
    this.authService.logout();
  }
}

