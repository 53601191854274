import {Component} from '@angular/core';
import {ColDef} from "ag-grid-community";
import {formatDateWithTime} from "../../../helpers/my_helper";
import { environment } from '../../../../environments/environment';
import { dropDownEmailSubscription, dropDownMarketingSubscription, dropDownPrintSubscription } from '../../../shared/models/static-data.model';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss'
})
export class SubscriptionComponent {

  constructor() {
  }

  url = environment.myAccountBaseUrl + `report/subscription`;

  subscription = "";
  jsonFilters: any = {}

  subscriptionOptions = [
    {
      label: 'Email subscriptions',
      options: [...dropDownEmailSubscription, ...dropDownMarketingSubscription]
    },
    {
      label: 'Print subscriptions',
      options: dropDownPrintSubscription
    }
  ]

  private emailSubscriptions: { [key: string]: string } = {
    weekly_vt_letter: 'Vet Times Weekly Newsletter',
    daily_vt_letter: 'Vet Times Daily Digest',
    monthly_vnt_letter: 'VN Times Monthly Newsletter',
    vbd_updates: 'VBD Updates',
    industry_updates: 'Industry Updates'
  };

  private printSubscriptions: { [key: string]: string } = {
    print_vettimes: 'Vet Times',
    print_vntimes: 'VN Times',
    print_vbj: 'VBJ'
  };

  colDefs: ColDef[] = [
    {headerName: 'Date subscription change', field: 'created_at', valueFormatter: (params: any) => formatDateWithTime(params.value), floatingFilter: true, filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'inRange'],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          const cellDate = new Date(cellValue);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        }
      }},
    {headerName: 'First Name', field: 'user.name', filter: true, floatingFilter: true},
    {headerName: 'Last Name', field: 'user.last_name', filter: true, floatingFilter: true},
    {headerName: 'Email', field: 'user.email', filter: true, floatingFilter: true, flex: 1},
    {
      headerName: 'Email subscription (Subscribed/Not subscribed)', field: 'changes', floatingFilter: true, flex: 1, sortable: false,
      cellRenderer: (params: any) => this.renderSubscription(params.value, this.emailSubscriptions)
    },
    {
      headerName: 'Print subscription (Subscribed/Not subscribed)', field: 'changes', floatingFilter: true, flex: 1, sortable: false,
      cellRenderer: (params: any) => this.renderSubscription(params.value, this.printSubscriptions)
    }
  ];

  private renderSubscription(changesJson: any, subscriptionTypes: { [key: string]: string }): string {
    const subscribed: string[] = [];
    let changes: any;

    if (typeof changesJson === 'string') {
      try {
        changes = JSON.parse(changesJson);
      } catch (error) {
        console.error("Failed to parse changesJson:", error);
        return ''; // Return empty if parsing fails
      }
    } else {
      // If it's already an object, assign it directly
      changes = changesJson;
    }

    if (!changes) {
      return ''; // Return empty if changes are not defined
    }

    Object.keys(subscriptionTypes).forEach(key => {
      if (changes[key]?.new) {
        subscribed.push(subscriptionTypes[key] + ' (Subscribed)');
      }
      if (!changes[key]?.new && changes[key]?.old) {
        subscribed.push(subscriptionTypes[key] + ' (Not subscribed)');
      }
    });

    return subscribed.join(', ');
  }

  searchSubscriptionStats() {

    this.jsonFilters = {};

    if (this.subscription) {
      this.jsonFilters = {
        subscription: this.subscription
      };
    }

  }

  handleClearFilters(event: any) {
    this.subscription = event.filter
  }
}
