export function formatDateWithTime(value: any): string {
  if (value) {
    const date = new Date(value);
    return date.toLocaleString();
  }
  return '';
}

export function subscriptionsField(subscription: string): string {
  let subscriptions: any = {
    weekly_vt_letter: 'Vet Times Weekly Newsletter',
    daily_vt_letter: 'Vet Times Daily Digest',
    monthly_vnt_letter: 'VN Times Monthly Newsletter',
    vbd_updates: 'VBD Updates',
    industry_updates: 'Industry Updates',
    print_vettimes: 'Vet Times',
    print_vntimes: 'VN Times',
    print_vbj: 'VBJ',
    weekly_job_letter: "Weekly",
    monthly_advice: "Monthly",
    subscribe_to_vbd_offers: "Subscribe to VBD Offers",
    subscribe_to_third_party_info: "Subscribe to third party info",
    subscribe_to_workplace_panel: "Subscribe to workplace panel"
  };

  return subscriptions[subscription];
}

export const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];
