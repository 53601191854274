import {Component, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "../../services/api.service";
import {AuthService} from "../../services/auth.service";
import {ColDef, GridApi, GridOptions, IGetRowsParams, IDatasource} from "ag-grid-community";

@Component({
  selector: 'Datatable',
  templateUrl: './datatable.component.html',
  styleUrl: './datatable.component.scss'
})
export class DatatableComponent {

  @Input() url: string = '';
  @Input() colDefs: ColDef[] = [];
  @Input() jsonFilters: any = {};
  @Input() exportFilter: boolean = true;

  @Output() onClearFilters = new EventEmitter<any>();  // Output event to notify the parent component

  overlayNoRowsTemplate = `<span class="ag-overlay-no-rows-center">Record not found</span>`;
  overlayLoadingTemplate = `<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>`;

  urlFilterParams: string = '';
  data: any[] = [];
  pagination = true;
  paginationPageSize = 10;
  paginationPageSizeSelector = [10, 20, 50, 100];

  private gridApi!: GridApi;
  gridOptions: GridOptions;

  constructor(private http: HttpClient, private apiService: ApiService, private authService: AuthService) {
    this.gridOptions = {
      pagination: true,
      paginationPageSize: 10,
      rowModelType: 'infinite',
      enableCellTextSelection: true,
      overlayNoRowsTemplate: this.overlayNoRowsTemplate,
      overlayLoadingTemplate: this.overlayLoadingTemplate,
      datasource: this.createDataSource()
    };
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  setLoading(value: boolean) {
    if (value) {
      this.gridApi.showLoadingOverlay(); // Show built-in loading overlay
    } else {
      this.gridApi.hideOverlay(); // Hide overlay (both loading and no-rows)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['jsonFilters']) {
      this.refreshData();
    }

    if (changes['clearFilter'] && changes['clearFilter'].currentValue) {
      this.clearFilters();
    }
  }

  clearFilters(): void {
    if (this.gridApi) {
      this.gridApi.setFilterModel(null);

    if (this.onClearFilters.observers.length > 0) {
      this.jsonFilters = {};  // Reset jsonFilters if the Output is used

      this.onClearFilters.emit({
        filter: "",
      });
    }
      this.refreshData();
    }
  }

  createDataSource(): IDatasource {
    return {
      getRows: (params: IGetRowsParams) => {
        ``
        const pageSize = params.endRow - params.startRow;
        const page = Math.floor(params.startRow / pageSize) + 1;
        const filters = params.filterModel;

        const sortModel = params.sortModel;

        // Extracting sort parameters
        let sortBy = '';
        let sortDir = '';

        if (sortModel.length > 0) {
          sortBy = sortModel[0].colId;
          sortDir = sortModel[0].sort;
        }

        const filterParams = Object.keys(filters).map(key =>
          filters[key].filter ? `${key}=${encodeURIComponent(filters[key].filter)}` : `${key}=${filters[key].type}&dateFrom=${filters[key].dateFrom.split(' ')[0]}${filters[key].dateTo ? `&dateTo=${filters[key].dateTo.split(' ')[0]}` : '' }`
        ).join('&');

        const jsonFiltersParams = Object.keys(this.jsonFilters)
          .map(key => `${key}=${encodeURIComponent(this.jsonFilters[key])}`)
          .join('&');

        this.urlFilterParams = `${filterParams ? `&${filterParams}` : ''}${jsonFiltersParams ? `&${jsonFiltersParams}` : ''}`;

        const url = `${this.url}?page=${page}&limit=${pageSize}${sortBy ? `&sort_by=${sortBy}` : ''}${sortDir ? `&sort_dir=${sortDir}` : ''}${this.urlFilterParams}`;

        const headers = new HttpHeaders({'Content-Type': 'application/json', Authorization: this.authService.getToken});
        this.setLoading(true);

        this.http.get(url, {headers: headers}).subscribe(
          (response: any) => {

            this.setLoading(false);
            response.data.total > 0 ? this.gridApi.hideOverlay() : this.gridApi.showNoRowsOverlay();
            params.successCallback(response.data.data, response.data.total);

          },
          (error) => {

            params.failCallback();
            this.setLoading(false);

          }
        );
      }
    };
  }

  exportData() {
    this.apiService.exportExcel(`${this.url}/export?${this.urlFilterParams}`)
  }

  refreshData(): void {
    if (this.gridApi) {
      this.gridApi.setGridOption("columnDefs", this.colDefs);
      this.gridApi.purgeInfiniteCache();
    }
  }
}
