<div class="wrap-auth">
  <div class="row h-100">
    <div class="col-lg-5 p-0">
      <app-layout bgImage="../../../assets/images/register-bg.png"></app-layout>
    </div>
    <div class="col-lg-7 p-0 col-12 ">
      <div class="right-section">
        <div class="right-section-content">
          <div class="onboarding-card">
            <h3 class="onboarding-card-title">Which emails would you like ?
            </h3>
            <div class="content">
              <div class="subscription-category">
                <div class="subscription-gategory-item">
                  <h4 class="gategory-title">Vet Times</h4>
                  <div class="subscription-item">
                    <div class="content">
                      <h5 class="list-title">Vet Times Weekly Newsletter</h5>
                      <p class="list-text">A weekly overview of news, articles and veterinary blogs, delivered every
                        Wednesday</p>
                    </div>
                    <div class="action">
                      <label class="switch-secondary" [ngClass]="{ 'd-none': loaders['weekly_vt_letter'] }">
                        <input type="checkbox" value="weekly_vt_letter" [(ngModel)]="subscriptions.weekly_vt_letter"
                               (change)=" trackSubscription($event, {'button': 'Weekly VT Letter', page: 'Email subscription'})">
                        <small></small>
                      </label>

                    </div>
                  </div>
                  <div class="subscription-item">
                    <div class="content">
                      <h5 class="list-title">Vet Times Daily Digest</h5>
                      <p class="list-text">A daily round-up of all the latest veterinary news from vettimes.co.uk</p>
                    </div>
                    <div class="action">
                      <label class="switch-secondary" [ngClass]="{ 'd-none': loaders['daily_vt_letter'] }">
                        <input type="checkbox" value="daily_vt_letter" [(ngModel)]="subscriptions.daily_vt_letter"
                               (change)=" trackSubscription($event, {'button': 'Daily VT Letter', page: 'Email subscription'})">
                        <small></small>
                      </label>

                    </div>
                  </div>
                </div>
                <div class="subscription-gategory-item">
                  <h4 class="gategory-title">VN Times</h4>
                  <div class="subscription-item">
                    <div class="content">
                      <h5 class="list-title">VN Times Monthly Newsletter</h5>
                      <p class="list-text">Keep up to date with all the latest from the profession, including what the
                        BVNA and RCVS are up to on your behalf, congress plans and CPD events.</p>
                    </div>
                    <div class="action">
                      <label class="switch-secondary" [ngClass]="{ 'd-none': loaders['monthly_vnt_letter'] }">
                        <input type="checkbox" value="monthly_vnt_letter" [(ngModel)]="subscriptions.monthly_vnt_letter"
                               (change)=" trackSubscription($event, {'button': 'Monthly VT Letter', page: 'Email subscription'})">
                        <small></small>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="subscription-gategory-item">
                  <h4 class="gategory-title">Veterinary Business Journal</h4>
                  <div class="subscription-item">
                    <div class="content">
                      <h5 class="list-title">VBD Updates</h5>
                      <p class="list-text">Content personalized and relevant to you regarding other organisations sent
                        directly from us. From product information to free online CPD.</p>
                    </div>
                    <div class="action">
                      <label class="switch-secondary" [ngClass]="{ 'd-none': loaders['vbd_updates'] }">
                        <input type="checkbox" value="vbd_updates" [(ngModel)]="subscriptions.vbd_updates"
                               (change)=" trackSubscription($event, {'button': 'VBD Updates', page: 'Email subscription'})">
                        <small></small>
                      </label>
                    </div>
                  </div>
                  <div class="subscription-item">
                    <div class="content">
                      <h5 class="list-title">Industry Updates</h5>
                      <p class="list-text">News and updates on all things VBD, including our products and services, new
                        product launches and relevant information tailored to you.</p>
                    </div>
                    <div class="action">
                      <label class="switch-secondary" [ngClass]="{ 'd-none': loaders['industry_updates'] }">
                        <input type="checkbox" value="industry_updates" [(ngModel)]="subscriptions.industry_updates"
                               (change)=" trackSubscription($event, {'button': 'Industry Updates', page: 'Email subscription'})">
                        <small></small>
                      </label>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between gap-3">
              <a href="" class="btn btn-auth btn-primary w-100 mt-lg-3 mt-2" (click)="saveEmailSubscription($event)">
                <app-spinner></app-spinner>
                <span *ngIf="!loader">Next</span>
              </a>

            </div>


          </div>

          <div
            class="product-card mb-4 border-0" *ngIf="show_print_subscriptions">
            <h5 class="product-card-title mb-3">Print Subscription</h5>
            <p class="text-dark fw-medium mb-4">
              Get news delivered directly to your doorstep with our print subscription!
            </p>
            <div class="">
              <button class="btn btn-primary" (click)="openPrintSubs(); trackButtonClick('Open print subscription')">Subscribe to print</button>
            </div>
          </div>
          <!-- print subscription modal  -->
          <!-- Modal -->
          <ng-template #printSubscription let-c="close" let-d="dismiss">
            <div class="modal-header">
              <h4 class="modal-title">Subscribe to print</h4>
              <button type="button" class="btn-close" aria-label="Close" (click)="closePrintSubs(); trackButtonClick('Close print subscription')"></button>
            </div>
            <div class="modal-body">
              <!-- step form progress bar  -->
              <div class="step-progress-bar">
                <div class="step-nav active" [ngClass]="{'done':steps['personal_details']}">
                  <p>Select subscription</p>
                  <div class="bullet">
                    <span></span>
                  </div>
                </div>
                <div class="step-nav" [ngClass]="{'active':steps['personal_details']}">
                  <p>Personal details</p>
                  <div class="bullet">
                    <span></span>
                  </div>
                </div>
              </div>
              <!-- ended  -->
              <!-- steppers content  -->
              <form [formGroup]="form" action="#" class="onboarding-form needs-validation" novalidate>
                <div class="stepper-tab" [ngClass]="{'d-none':!steps['subscription']}">
                  <div class="modal-icon">
                    <img src="../../../assets/images/news-icon.svg" alt="">
                  </div>
                  <h4 class="title-icon">Please select print subscriptions</h4>
                  <p class="text-icon">Choose your preferred print subscription to receive Vet Times at your doorstep!</p>
                  <div class="subscription-category">
                    <div class="subscription-gategory-item">
                      <div class="subscription-item">
                        <div class="content">
                          <h5 class="list-title">Vet Times</h5>
                          <p class="list-text">A weekly overview of news, articles and veterinary blogs, delivered every
                            Wednesday</p>
                        </div>
                        <div class="action">
                          <label class="switch-secondary">
                            <input type="checkbox" formControlName="print_vettimes" value="Vet Times"
                                   (change)="onCheckboxChange($event); trackSubscription($event, {'button': 'Print Vet Times', page: 'Email subscription'})">
                            <small></small>
                          </label>
                        </div>
                      </div>
                      <div class="subscription-item">
                        <div class="content">
                          <h5 class="list-title">VN Times</h5>
                          <p class="list-text">A daily round-up of all the latest veterinary news from vettimes.co.uk</p>
                        </div>
                        <div class="action">
                          <label class="switch-secondary">
                            <input type="checkbox" formControlName="print_vntimes" value="VN Times"
                                   (change)="onCheckboxChange($event); trackSubscription($event, {'button': 'Print VN Times', page: 'Email subscription'})">
                            <small></small>
                          </label>
                        </div>
                      </div>
                      <div class="subscription-item">
                        <div class="content">
                          <h5 class="list-title">VBJ</h5>
                          <p class="list-text">Content personalized and relevant to you regarding other organisations sent directly from us. From product information to free online CPD.</p>
                        </div>
                        <div class="action">
                          <label class="switch-secondary">
                            <input type="checkbox" formControlName="print_vbj" (change)="onCheckboxChange($event); trackSubscription($event, {'button': 'Print VBJ', page: 'Email subscription'})">
                            <small></small>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="stepper-tab" [ngClass]="{'d-none':!steps['personal_details']}">
                  <div class="modal-icon">
                    <img src="../../../assets/images/location-icon.svg" alt="">
                  </div>
                  <h4 class="title-icon">Please provide your details & delivery address</h4>
                  <p class="text-icon">Fill in your information to ensure timely and accurate delivery of your
                    subscription.</p>

                  <div class="mb-lg-4 mb-3">
                    <label for="rcvs" class="form-label required">Rcvs number <a href="#" (click)="findRcvsNumber($event); trackButtonClick('Find Rcvs number')">(Find it here)
                    </a>
                    </label>

                    <input type="text" class="form-control" [ngClass]="{'is-invalid': (form.get('rcvs')?.errors && form.get('rcvs')?.touched) }" formControlName="rcvs"
                      (input)="validateRcvsInput($event)" placeholder="" id="rcvs" maxlength="7" />

                    <div class="invalid-feedback" *ngIf="form.get('rcvs')?.hasError('apiError') && form.get('rcvs')?.touched">
                    {{errorMessage?.rcvs}}
                    </div>

                    <div *ngIf="form.get('rcvs')?.hasError('required') && form.get('rcvs')?.touched" class="invalid-feedback">
                      Rcvs number is required.
                    </div>

                    <div *ngIf="form.get('rcvs')?.hasError('pattern') && form.get('rcvs')?.touched" class="invalid-feedback">
                      Rcvs number is invalid.
                    </div>

                    <div *ngIf="(form.get('rcvs')?.hasError('maxlength') || form.get('rcvs')?.hasError('minlength')) && form.get('rcvs')?.touched &&
                    !form.get('rcvs')?.hasError('pattern')" class="invalid-feedback">
                      Rcvs number must be exactly 7 characters long.
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="form-label required">Job title</label>
                    <input class="form-control" type="text" placeholder="Please enter your job title"
                           [ngClass]="{ 'is-invalid': form.get('job_title')?.errors && form.get('job_title')?.touched }"
                           formControlName="job_title" maxlength="99">
                    <div *ngIf="form.get('job_title')?.hasError('required') && form.get('job_title')?.touched"
                         class="invalid-feedback"> Job title is required.
                    </div>
                    <div *ngIf="form.get('job_title')?.errors?.['invalidAlphaNumeric']"
                         class="invalid-feedback"> Job title is invalid.
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="form-label required">Practice name</label>
                    <input class="form-control" type="text" placeholder="Please enter your practice name"
                           [ngClass]="{ 'is-invalid': form.get('practice_name')?.errors && form.get('practice_name')?.touched }"
                           formControlName="practice_name" maxlength="99">
                    <div *ngIf="form.get('practice_name')?.hasError('required') && form.get('practice_name')?.touched"
                         class="invalid-feedback"> Practice name is required.
                    </div>
                    <div *ngIf="form.get('practice_name')?.hasError('pattern') && form.get('practice_name')?.touched"
                         class="invalid-feedback"> Practice name is invalid.
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label required">Practice postcode</label>
                    <input class="form-control" type="text" placeholder="Please enter your practice postcode" maxlength="8"
                           [ngClass]="{ 'is-invalid': form.get('practice_postcode')?.errors && form.get('practice_postcode')?.touched }"
                           formControlName="practice_postcode">
                    <div *ngIf="form.get('practice_postcode')?.hasError('required') && form.get('practice_postcode')?.touched"
                         class="invalid-feedback"> Practice postcode is required.
                    </div>
                    <div *ngIf="form.get('practice_postcode')?.hasError('pattern') && form.get('practice_postcode')?.touched"
                         class="invalid-feedback"> Practice postcode is invalid.
                    </div>
                  </div>
                  <div class="mb-3 search-postcode">
                    <input class="form-control" type="text" #postcodeInput
                           placeholder="Enter your delivery address postal code" maxlength="8">
                    <button class="btn btn-primary-light btn-sm" (click)="searchPostcode(postcodeInput)"><span>
                      <img *ngIf="!loader" src="../../../assets/images/search-icon.svg" alt=""></span>
                      <span *ngIf="!loader">Search</span>
                      <app-spinner type="ball-clip-rotate" color="black"></app-spinner>
                    </button>
                    <div id="address-list" class="address-list" [ngClass]="{ 'd-none': !show_address_list }">
                      <ul>
                        <li *ngFor="let address of addresses" (click)="selectAddress(address)"
                            class="address-list-item">{{address.display}}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label required">Address line 1</label>
                    <input class="form-control" type="text" formControlName="address_1" maxlength="254"
                           [ngClass]="{ 'is-invalid': form.get('address_1')?.errors && form.get('address_1')?.touched }">
                    <div *ngIf="form.get('address_1')?.hasError('required') && form.get('address_1')?.touched"
                         class="invalid-feedback"> Address line 1 is required.
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Address line 2</label>
                    <input class="form-control" type="text" formControlName="address_2" maxlength="254">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Address line 3</label>
                    <input class="form-control" type="text" formControlName="address_3" maxlength="254">
                  </div>
                  <div class="mb-3">
                    <label class="form-label required">Town</label>
                    <input class="form-control" type="text" formControlName="town" maxlength="254"
                           [ngClass]="{ 'is-invalid': form.get('town')?.errors && form.get('town')?.touched }">
                    <div *ngIf="form.get('town')?.hasError('required') && form.get('town')?.touched"
                         class="invalid-feedback"> Town is required.
                    </div>
                    <div *ngIf="form.get('town')?.hasError('pattern') && form.get('town')?.touched"
                         class="invalid-feedback"> Town is invalid.
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label required">County</label>
                    <input class="form-control" type="text" formControlName="county" maxlength="254"
                           [ngClass]="{ 'is-invalid': form.get('county')?.errors && form.get('county')?.touched }">
                    <div *ngIf="form.get('county')?.hasError('required') && form.get('county')?.touched"
                         class="invalid-feedback"> County is required.
                    </div>
                    <div *ngIf="form.get('county')?.hasError('pattern') && form.get('county')?.touched"
                         class="invalid-feedback"> County is invalid.
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Postcode</label>
                    <input class="form-control" type="text" formControlName="postcode" maxlength="8" readonly>
                  </div>
                  <div class="mb-3">
                    <label class="form-label required">Country</label>
                    <input class="form-control" type="text" formControlName="country" maxlength="254"
                           [ngClass]="{ 'is-invalid': form.get('country')?.errors && form.get('country')?.touched }">
                    <div *ngIf="form.get('country')?.hasError('required') && form.get('country')?.touched"
                         class="invalid-feedback"> Country is required.
                    </div>
                    <div *ngIf="form.get('country')?.hasError('pattern') && form.get('country')?.touched"
                         class="invalid-feedback"> Country is invalid.
                    </div>
                  </div>
                </div>
              </form>
              <!-- steppers content ended -->
            </div>
            <div class="modal-footer flex-nowrap gap-3">
              <button type="button" class="btn btn-outline w-100" aria-label="Close" (click)="back();" [ngClass]="{'d-none':!steps['personal_details']}">Back
              </button>
              <button type="button" class="btn btn-primary w-100 btn-auth"
                      (click)="trackButtonClick(is_print_subs_checked ? (is_print_subs_checked && steps['personal_details'] ? 'Save' : 'Next') : (steps['personal_details'] ? 'Save' : 'Apply & Close')); next()">
                <app-spinner name="print_subscription_spinner"></app-spinner>
                <span *ngIf="!print_subscription_loader">
                  {{
                  is_print_subs_checked ? (is_print_subs_checked && steps['personal_details'] ? 'Save' : 'Next') :
                    (steps['personal_details'] ? 'Save' : 'Apply & Close')
                  }}
                </span>
              </button>
            </div>
          </ng-template>
          <!-- ended  -->
          <visit-products></visit-products>
        </div>
      </div>
    </div>
  </div>
</div>
