<div class="d-md-flex justify-content-between mb-md-3 mb-2">
  <div></div>
  <div class="d-flex align-items-center mt-md-0 mt-3">
    <input type="text" class="form-control"
      (change)="dateSelection(selected)"
      style="width: 325px;"
      ngxDaterangepickerMd [(ngModel)]="selected" startKey="start"
      endKey="end"
      [showCustomRangeLabel]="true" [alwaysShowCalendars]="true"
      [customRangeDirection]="true"
      [locale]="{ displayFormat: 'DD MMM YYYY', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
      [showCancel]="true"
      [showWeekNumbers]="true" [ranges]="ranges"
      [linkedCalendars]="true" placeholder="Select Range"
    >
    <button class="btn btn-sm btn-primary icon-btn ms-2" style="height: 50px; width: 114px;" *ngIf="todayDate" (click)="getTodayDate()">Today</button>
  </div>
</div>
