import { Component, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import moment from 'moment';
import { isPlatformBrowser } from '@angular/common';
import { monthNames } from '../../../helpers/my_helper';
import {CookieService} from "ngx-cookie-service";

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-kpi-reporting',
  templateUrl: './kpi-reporting.component.html',
  styleUrl: './kpi-reporting.component.scss'
})
export class KpiReportingComponent {

  token = this.cookieService.get('sso_signed');

  kpiReportingData: any;
  loggedInUser: any = 0;
  averageSession: any = 0;
  totalAccounts: any = 0;

  currentYear = moment().format('YYYY');
  lastYear = moment().subtract(1, 'years').format('YYYY');

  year: any = this.currentYear;

  date_from = moment().format('YYYY-MM-DD');
  date_to = moment().format('YYYY-MM-DD');

  private loggedInSessionsbarChartRoot!: am5.Root;
  private averageSessionsbarChartRoot!: am5.Root;
  private monthlyBarChartRoot!: am5.Root;

  constructor(private apiService: ApiService, @Inject(PLATFORM_ID) private platformId: Object, private zone: NgZone, private cookieService: CookieService) {}

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  onDateRangeChanged(dateRange: { start: string, end: string }) {
    this.date_from = dateRange.start;
    this.date_to = dateRange.end;
    this.getKpiReporting();
  }

  onChangeYear(e: any) {
    this.year = e.target.value == 'this_year' ? this.currentYear : this.lastYear;
    this.getKpiReporting();
  }

  getBody() {
    return { range: { from: this.date_from, to: this.date_to }, year: this.year };
  }

  getKpiReporting() {

    if (this.token) {

      let headers = {
        Authorization: this.token
      }

      this.apiService.httpPostMyAccount('report/kpi/reporting', this.getBody(), headers).subscribe((response: any) => {

        this.kpiReportingData = response.data.kpireportingData;
        const {loggedInSessionsBarChartData, averageSessionsBarChartData, barChartMonthlyData} = this.setDataInChart(response.data);

        this.updateChartData(this.loggedInSessionsbarChartRoot, loggedInSessionsBarChartData)
        this.updateChartData(this.averageSessionsbarChartRoot, averageSessionsBarChartData)
        this.updateChartData(this.monthlyBarChartRoot, barChartMonthlyData)
      });

    }
  }

  setDataInChart(data: any) {
    const { kpireportingData, monthlyKPIData } = data;

    const [account, accountsValidated, loggedInSessions, uniqueLoggedInUsers, averageSessions] = kpireportingData.map((item: any) => item?.value || 0);

    this.loggedInUser = loggedInSessions;
    this.averageSession = averageSessions;

    const monthData = monthNames.reduce((acc: any, monthName) => {
      const totalAccountsCreated = monthlyKPIData.find((item: any) => item.month === monthName)?.totalAccountsCreated || 0;
      acc[monthName] = totalAccountsCreated;
      this.totalAccounts += totalAccountsCreated;
      return acc;
    }, {});

    const createChartData = (dataMap: any) => [{ category: "", ...dataMap }];

    return {
      loggedInSessionsBarChartData: createChartData({ logged_in_sessions: loggedInSessions, unique_logged_in_users: uniqueLoggedInUsers, accounts_validated: accountsValidated }),
      averageSessionsBarChartData: createChartData({ account, unique_logged_in_users: uniqueLoggedInUsers, average_sessions: averageSessions }),
      barChartMonthlyData: createChartData(monthData),
    };
  }

  updateChartData(root: any, newData: any): void {
    if (!root) return;

    const chart: any = root.container.children.getIndex(0) as am5xy.XYChart;
    const modal = root.modal ||= am5.Modal.new(root, { content: "No data available for this period" });

    const hasData = newData.every((item: any) => Object.values(item).every(value => !value));

    if (hasData) {
      modal.open();
      newData = newData.map((item: any) =>
        Object.fromEntries(Object.entries(item).map(([key, value]) => [key, value || 1]))
      );
    } else {
      modal.close();
    }

    chart.xAxes.getIndex(0).data.setAll(newData);
    chart.series.each((series: any) => series.data.setAll(newData));
  }

  ngAfterViewInit() {
    this.implementLoggedInSessionsBarChart()
    this.implementAverageSessionsBarChart()
    this.implementMonthlyBarChart()
  }

  implementLoggedInSessionsBarChart(){
    let root = am5.Root.new("loggedInSessionsBarChart");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "category"
      })
    );
    xAxis.data.setAll([]);

    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Logged In Sessions",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "logged_in_sessions",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}"
        })
      })
    );
    series1.set("fill", am5.color("#ac1d54"));
    series1.columns.template.set("tooltipText", "{valueY}");
    series1.data.setAll([]);

    let series2 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Unique Logged In Sessions",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "unique_logged_in_users",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}"
        })
      })
    );
    series2.set("fill", am5.color("#1078c0"));
    series2.columns.template.set("tooltipText", "{valueY}");
    series2.data.setAll([]);

    let series3 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Accounts validated",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "accounts_validated",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}"
        })
      })
    );
    series3.set("fill", am5.color(0xF2AA6B));
    series3.columns.template.set("tooltipText", "{valueY}");
    series3.data.setAll([]);

    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: am5.GridLayout.new(root, {
        maxColumns: 2
      })
    }));

    legend.data.setAll(chart.series.values);
    legend.itemContainers.template.set("width", am5.percent(100 / 3));

    this.loggedInSessionsbarChartRoot = root;
  }

  implementAverageSessionsBarChart(){
    let root = am5.Root.new("averageSessionsBarChart");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "category"
      })
    );
    xAxis.data.setAll([]);

    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Accounts Created",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "account",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}"
        })
      })
    );
    series1.set("fill", am5.color("#ac1d54"));
    series1.columns.template.set("tooltipText", "{valueY}");
    series1.data.setAll([]);

    let series2 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Unique Logged In Sessions",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "unique_logged_in_users",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}"
        })
      })
    );
    series2.set("fill", am5.color("#1078c0"));
    series2.columns.template.set("tooltipText", "{valueY}");
    series2.data.setAll([]);

    let series3 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Average Sessions",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "average_sessions",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}"
        })
      })
    );
    series3.set("fill", am5.color(0xF2AA6B));
    series3.columns.template.set("tooltipText", "{valueY}");
    series3.data.setAll([]);

    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: am5.GridLayout.new(root, {
        maxColumns: 2
      })
    }));

    legend.data.setAll(chart.series.values);
    legend.itemContainers.template.set("width", am5.percent(100 / 3));

    this.averageSessionsbarChartRoot = root;
  }

  implementMonthlyBarChart(){
    let root = am5.Root.new("monthlyBarChart");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "category"
      })
    );
    xAxis.data.setAll([]);

    monthNames.forEach((month, index) => {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: monthNames[index].substring(0, 3),
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: month,
          categoryXField: "category",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}"
          })
        })
      );
      series.columns.template.set("tooltipText", "{valueY}");
      series.data.setAll([]);
    });

    let legend = chart.children.push(
      am5.Legend.new(root, {
        layout: root.horizontalLayout,  // Set horizontal layout
        centerX: am5.percent(-4),
        centerY: am5.percent(100),
        width: am5.percent(100),  // Ensure the legend takes full width
      })
    );

    legend.data.setAll(chart.series.values);
    legend.itemContainers.template.set("width", am5.percent(100 / 12));

    this.monthlyBarChartRoot = root;
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.loggedInSessionsbarChartRoot || this.averageSessionsbarChartRoot || this.monthlyBarChartRoot) {
        this.loggedInSessionsbarChartRoot.dispose();
        this.averageSessionsbarChartRoot.dispose();
        this.monthlyBarChartRoot.dispose();
      }
    });
  }
}
