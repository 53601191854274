<section class="dashboard-panel mb-lg-4 mb-3">
<div class="card parent">
  <div class="d-lg-flex justify-content-between align-items-center">
    <h3 class="page-title mb-lg-0 fw-bold">Report Overview</h3>
    <app-date-range-selector [todayDate]="true" (dateRangeChanged)="onDateRangeChanged($event)"></app-date-range-selector>
  </div>

    <div class="row row-cols-1 row-cols-lg-5 row-cols-md-2 gx-md-3 g-2 g-lg-3 mt-lg-3 mt-0">
      <div class="mt-lg-3 kpi-reporting-loop-card" *ngFor="let row of kpiReportingData">
        <div class="dashboard-card">
          <div class="content">
            <p class="text">{{row.label}}</p>
            <h4 class="value">{{row.value}}</h4>
          </div>

        </div>
      </div>
    </div>

    <div class="row  mt-0 gy-4">
      <div class="col-lg-6">
        <div class="dashboard-card">
          <div class="content mb-3">
            <p class="text">Logged in Sessions</p>
            <h4 class="value">{{loggedInUser}}</h4>
          </div>
          <div id="loggedInSessionsBarChart" style="width: 100%; height: 500px"></div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="dashboard-card">
          <div class="content mb-3">
            <p class="text">Average Sessions</p>
            <h4 class="value">{{ averageSession.toFixed(2) }}</h4>
          </div>
          <div id="averageSessionsBarChart" style="width: 100%; height: 500px"></div>
        </div>
      </div>
    </div>
</div>

  <div class="row  mt-5">
    <div class="col-lg-12 ">
      <div class="dashboard-card">
        <div class="d-flex justify-content-between align-items-center mb-3 w-100">
          <div class="content w-100">
            <p class="text fw-bold">Total Accounts</p>
            <h4 class="value">{{totalAccounts}}</h4>
          </div>
          <select class="form-select w-25" aria-label="Default select example"(change)="onChangeYear($event)">
            <option selected value="this_year">This year</option>
            <option value="last_year">Last year</option>
          </select>
        </div>

        <div id="monthlyBarChart" style="width: 100%; height: 500px"></div>
      </div>
    </div>

  </div>

</section>

