import { Component, Input, EventEmitter, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrl: './date-range-selector.component.scss'
})
export class DateRangeSelectorComponent {
  selected: any = {start: moment(), end: moment() };
  alwaysShowCalendars!: boolean;

  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  date_from = moment().format('YYYY-MM-DD');
  date_to = moment().format('YYYY-MM-DD');

  @Input() todayDate: any = false
  @Output() dateRangeChanged = new EventEmitter<{ start: string, end: string }>();

  constructor() {
    this.alwaysShowCalendars = true;
  }

  isInvalidDate = (m: moment.Moment) => this.invalidDates.some(d => d.isSame(m, 'day'));

  dateSelection(event: any) {
    const { start, end } = event;
    this.date_from = start?.toISOString().slice(0, 10) || this.date_from;
    this.date_to = end?.toISOString().slice(0, 10) || this.date_to;
    this.emitDateRange();
  }

  getTodayDate() {
    this.selected = {start: moment(), end: moment() };
    this.date_from = moment().format('YYYY-MM-DD');
    this.date_to = moment().format('YYYY-MM-DD');
    this.emitDateRange();
  }

  private emitDateRange() {
    this.dateRangeChanged.emit({ start: this.date_from, end: this.date_to });
  }
}
