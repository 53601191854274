import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from "./api.service";
import {AuthService} from './auth.service';
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private apiService: ApiService, private authService: AuthService, private cookieService: CookieService) {
  }

  public header: any = {
    Authorization: this.authService.getToken,
  }

  saveEmailSubscription(subscription: any): Observable<any> {
    const payload = this.createEmailSubscriptionPayload(subscription)
    return this.apiService.httpPostMyAccount('subscription/email', payload, this.header);
  }

  loadEmailSubscription(): Observable<any> {
    return this.apiService.httpGetMyAccount('subscription/email', this.header);
  }


  createEmailSubscriptionPayload(subscription: any) {

    const payload: {
      subscribe: string[];
      unsubscribe: string[];
      vbd_updates?: number;
      industry_updates?: number;
    } = {
      subscribe: [] as string[],
      unsubscribe: [] as string[],
      vbd_updates: subscription.vbd_updates,
      industry_updates: subscription.industry_updates
    }

    for (const key in subscription) {
      if (!["vbd_updates", "industry_updates"].includes(key)) {
        if (subscription[key]) {
          payload.subscribe.push(key);
        } else {
          payload.unsubscribe.push(key);
        }
      }
    }
    return payload;
  }
}
