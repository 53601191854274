// activity-log.model.ts

export interface ActivityLog {
  id: number;
  user_id: number;
  model_id: number;
  model_name: string;
  changes: string;
  created_at: string;
  updated_at: string;
}

export interface ActivityLogs {
  [date: string]: ActivityLog[];
}

export interface ActivityLogsData {
  user_id: number;
  email: string;
  name: string;
  last_name: string;
  id: number;
  activity_logs: ActivityLogs;
}

export interface ChangeValue {
  new: number;
  old: number;
}

export interface Changes {
  [key: string]: ChangeValue;
}

export interface FieldsMapping {
  [key: string]: string;
}

export const fieldsMapping: FieldsMapping = {
  'work_email': 'Work Email',
  'title': 'Title',
  'name': 'Name',
  'middle_name': 'Middle Name',
  'last_name': 'Last Name',
  'maiden_name': 'Maiden Name',
  'rcvs': 'RCVS',
  'job_role': 'Job Role',
  'country_of_residence': 'Country of Residence',
  'additional_certificate': 'Additional Certificate',
  'phone_number': 'Phone Number',
  'workplace': 'Workplace',
  'year_qualification': 'Year Qualification',
  'uni_country': 'University Country',
  'address_1': 'Address Line 1',
  'address_2': 'Address Line 2',
  'address_3': 'Address Line 3',
  'town': 'Town',
  'country': 'Country',
  'county': 'County',
  'postcode': 'Postcode',
  'created_at': 'Created At',
  'updated_at': 'Updated At',
  'weekly_vt_letter': 'Weekly VT Letter',
  'daily_vt_letter': 'Daily VT Letter',
  'monthly_vnt_letter': 'Monthly VNT Letter',
  'vbd_updates': 'VBD Updates',
  'industry_updates': 'Industry Updates',
  'university': 'University',
  'job_title': 'Job Title',
  'practice_name': 'Practice Name',
  'practice_postcode': 'Practice Postcode',
  'work_address': 'Work Address',
  'job_types_other': 'Job Types (Other)',
  'job_additional_role': 'Job Additional Role',
  'email': 'Email',
  'deleted': 'Deleted',
  'deleted_at': 'Deleted At',
  'print_vettimes': 'Print VetTimes',
  'print_vntimes': 'Print VN Times',
  'print_vbj': 'Print VBJ',
  'home_address': 'Home Address',
  'monthly_advice': 'Monthly Advice',
  'weekly_job_letter': 'Weekly Job Letter',
  'subscribe_to_vbd_offers': 'Subscribe to VBD Offers',
  'subscribe_to_third_party_info': 'Subscribe to Third Party Info',
  'subscribe_to_workplace_panel': 'Subscribe to Workplace Panel',
  'validated': 'Validated',
  'validated_at': 'Validated At'
}
