import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidator} from "../validators/form.validator";

export class EmailSubscriptionValidation {

  static createPrintSubscriptionForm(fb: FormBuilder): FormGroup {
    return fb.group({
      job_title: ['', [FormValidator.requiredField('Job title is required.'), FormValidator.alphaNumeric()]],
      practice_name: ['', [FormValidator.requiredField('Practice name is required.'), Validators.pattern(/^[a-zA-Z0-9 ]*$/)]],
      practice_postcode: ['', [FormValidator.requiredField('Practice_postcode is required.'), Validators.pattern(/^[a-zA-Z0-9 ]*$/)]],
      address_1: ['', FormValidator.requiredField('Address 1 is required.')],
      address_2: [''],
      address_3: [''],
      town: ['', [FormValidator.requiredField('Town is required.'), Validators.pattern(/^[a-zA-Z -]*$/)]],
      county: ['', [FormValidator.requiredField('County is required.'), Validators.pattern(/^[a-zA-Z ]*$/)]],
      country: ['', [FormValidator.requiredField('Country is required.'), Validators.pattern(/^[a-zA-Z ]*$/)]],
      postcode: [''],
      print_vettimes: [],
      print_vntimes: [],
      print_vbj: [],
      rcvs: [
        '',
        [
          FormValidator.requiredField('Rcvs number is required.'),
          Validators.minLength(7),
          Validators.maxLength(7),
          Validators.pattern(/^[a-zA-Z0-9]+$/),
        ],
      ],
    });
  }
}
