export const dropDownWorkplace: { key: string; value: string }[] = [
  {key: 'lo', value: 'Locum'},
  {key: 'sa', value: 'Small animal practice'},
  {key: 'ma', value: 'Mixed animal practice'},
  {key: 'ep', value: 'Equine practice'},
  {key: 'fp', value: 'Farm practice'},
  {key: 'ind', value: 'Industry'},
  {key: 'uni', value: 'University'},
  {key: 'ot', value: 'Other'},
];

export const dropDownEmailSubscription: { key: string; value: string }[] = [
  { key: 'weekly_vt_letter', value: 'Vet Times Weekly Newsletter' },
  { key: 'daily_vt_letter', value: 'Vet Times Daily Digest' },
  { key: 'monthly_vnt_letter', value: 'VN Times Monthly Newsletter' }
];

export const dropDownPrintSubscription: { key: string; value: string }[] = [
  { key: 'print_vettimes', value: 'Vet Times' },
  { key: 'print_vntimes', value: 'VN Times' },
  { key: 'print_vbj', value: 'VBJ' }
];

export const dropDownJobSubscription: { key: string; value: string }[] = [
  { key: 'weekly_job_letter', value: 'Weekly' },
  { key: 'monthly_advice', value: 'Montly' },
  { key: 'subscribe_to_vbd_offers', value: 'Subscribe to VBD offers' },
  { key: 'subscribe_to_third_party_info', value: 'Subscribe to third party info' },
  { key: 'subscribe_to_workplace_panel', value: 'Subscribe to workplace panel' }
];

export const dropDownMarketingSubscription: { key: string; value: string }[] = [
  { key: 'vbd_updates', value: 'VBD Updates' },
  { key: 'industry_updates', value: 'Industry Updates' }
];
