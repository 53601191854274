import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from "./api.service";
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private apiService: ApiService, private authService: AuthService) {
  }

  public header: any = {
    Authorization: this.authService.getToken,
  }

  getDashboardTiles(): Observable<any> {
    return this.apiService.httpGetMyAccount(`admin/get-dashboard-data`, this.header);
  }

}
