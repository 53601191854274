<div class="wrap-auth">
  <div class="row h-100">
    <div class="col-lg-5 p-0">
      <app-layout bgImage="../../../assets/images/register-bg.png"></app-layout>
    </div>
    <div class="col-lg-7 p-0 col-12">
      <div class="right-section">
        <div class="right-section-content">

          <div class="onboarding-card" [ngClass]="{'d-none': success_card}">
            <h3 class="onboarding-card-title">Professional details
            </h3>
            <form [formGroup]="professionalDetailForm" action="#" class="onboarding-form needs-validation" novalidate>
              <div>
                <div class="mb-lg-4 mb-3 ">
                  <label class="form-label required">Workplace</label>
                  <select class="form-select" [ngClass]="{ 'is-invalid': (professionalDetailForm.get('workplace')?.touched && professionalDetailForm.get('workplace')?.errors) }"
                  aria-label="Default select example" formControlName="workplace">
                    <option selected disabled>Please select</option>
                    <option *ngFor="let workplace of workplaces" [value]="workplace.key">{{workplace.value}}</option>
                  </select>

                  <div class="invalid-feedback" *ngIf="professionalDetailForm.get('workplace')?.hasError('required')">
                    Workplace is required
                  </div>

                </div>
                <div class="mb-lg-4 mb-3 ">
                  <label class="form-label" [ngClass]="{ 'required': professionalDetailForm.get('workplace')?.value !== 'lo' }">Work address</label>
                  <input type="text" placeholder="Please enter your work address" class="form-control"
                         [ngClass]="{ 'is-invalid': (professionalDetailForm.get('work_address')?.touched && professionalDetailForm.get('work_address')?.errors) }"
                         formControlName="work_address">
                  <div class="invalid-feedback" *ngIf="professionalDetailForm.get('work_address')?.hasError('required')">
                    Work address is required
                  </div>
                </div>

                <div class="mb-lg-4 mb-3" *ngIf="showStudentField">
                  <label class="form-label required">University</label>
                  <input type="text" placeholder="Enter university name" class="form-control"
                         [ngClass]="{ 'is-invalid': (professionalDetailForm.get('university')?.touched && professionalDetailForm.get('university')?.errors) }"
                         formControlName="university">
                  <div class="invalid-feedback" *ngIf="professionalDetailForm.get('university')?.hasError('required')">
                    University is required
                  </div>
                </div>

                <div class="mb-lg-4 mb-3" *ngIf="showStudentField">
                  <label class="form-label required">University country</label>
                  <select
                    class="form-select is-invalid"
                    [ngClass]="{
                      'is-invalid':
                      professionalDetailForm.get('uni_country')?.errors &&
                      professionalDetailForm.get('uni_country')?.touched
                    }"
                    aria-label="Default select example"
                    formControlName="uni_country"
                    id="uni_country"
                    (change)="onCountryChange($event)"
                  >
                    <option value="" disabled>Please select</option>
                    <option *ngFor="let country of countries"
                            [value]="country.country_name">{{ country.country_name }}</option>
                  </select>

                  <div
                    *ngIf="
                    professionalDetailForm.get('uni_country')?.errors &&
                    professionalDetailForm.get('uni_country')?.touched
                    "
                    class="invalid-feedback"
                  >
                    University country is required.
                  </div>
                </div>

                <div class="mb-lg-4 mb-3" *ngIf="showStudentField">
                  <label class="form-label required">Year of qualification</label>

                  <input type="number" formControlName="year_qualification" placeholder="Enter your year of qualification"
                         class="form-control" min="{{currentYear}}" oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  [ngClass]="{ 'is-invalid': (professionalDetailForm.get('year_qualification')?.touched && professionalDetailForm.get('year_qualification')?.errors) }">

                  <div class="invalid-feedback" *ngIf="professionalDetailForm.get('year_qualification')?.hasError('required')">
                    Year of qualification is required
                  </div>
                  <div class="invalid-feedback" *ngIf="professionalDetailForm.get('year_qualification')?.hasError('min')">
                    Year of qualification must be no earlier than {{currentYear}}.
                  </div>
                  <div class="invalid-feedback" *ngIf="professionalDetailForm.get('year_qualification')?.hasError('max')">
                    Year of qualification must be no later than {{maxYear}}.
                  </div>
                </div>



                <div class="d-flex justify-content-between gap-3">
                  <a
                    href="" routerLink="/subscription/setting"
                    class="btn btn-outline w-100 mt-lg-3 mt-2">
                    Back
                  </a>
                  <a href="#"
                  class="btn btn-auth btn-primary w-100 mt-lg-3 mt-2" (click)="saveProfessionalDetails($event); trackButtonClick('Save professional detail')">
                  <app-spinner></app-spinner>
                  <span *ngIf="!loader">  Confirm </span>
               </a>
                </div>

              </div>
            </form>

          </div>

          <div class="onboarding-card" [ngClass]="{'d-none': !success_card}">
            <h3 class="onboarding-card-title">Your profile has been updated.</h3>

            <div class="content">
              <div class="icon-wrap">
                <img
                  src="../../../assets/images/account-created-icon.svg"
                  alt=""
                />
              </div>
          <visit-products></visit-products>


            </div>
          </div>


        </div>
      </div>
    </div>


  </div>
</div>
