import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubscriptionsRoutingModule} from './subscriptions-routing.module';
import {ProfessionalDetailsComponent} from './professional-details/professional-details.component';
import {EmailSubscriptionComponent} from './email-subscription/email-subscription.component';
import {EmailSubscriptionSettingComponent} from './email-subscription-setting/email-subscription-setting.component';
import {LayoutModule} from "../layout/layout.module";
import {SpinnerModule} from "../shared/components/spinner/spinner.module";
import { FormsModule } from "@angular/forms";
import {ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from "ngx-toastr";
import {VisitProductsModule} from "../shared/components/visit-products/visit-products.module";

@NgModule({
  declarations: [
    ProfessionalDetailsComponent,
    EmailSubscriptionComponent,
    EmailSubscriptionSettingComponent,
  ],
  imports: [
    CommonModule,
    SubscriptionsRoutingModule,
    LayoutModule,
    SpinnerModule,
    VisitProductsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
  ]

})
export class SubscriptionsModule {
}
