import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmitterService {

  private subjects: any;

  constructor() {
    this.subjects = {};
  }

  emit(name: any, data: any) {
    let fnName = this._createName(name);
    if (!this.subjects[fnName])
      this.subjects[fnName] = new Subject();
    this.subjects[fnName].next(data);
  }

  listen(name: any, handler: any) {
    let fnName = this._createName(name);
    if (!this.subjects[fnName])
      this.subjects[fnName] = new Subject();
    return this.subjects[fnName].subscribe(handler);
  }

  unlisten(name: any) {
    let fnName = this._createName(name);
    this.subjects[fnName].unsubscribe();
    this.subjects[fnName] = null;
    return
  }

  _createName(name: any) {
    return `$ ${name}`;
  }
}
