import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from "ngx-toastr";
import {AdminRoutingModule} from "./admin-routing.module";
import {DashboardComponent} from './dashboard/dashboard.component';
import {LayoutComponent} from './layout/layout.component';
import {HeaderComponent} from './layout/header/header.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {NgbNavModule, NgbTooltipModule, NgbCollapseModule, NgbAccordionModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {MembersComponent} from './members/members.component';
import {UnverifiedMembersComponent} from './unverified-members/unverified-members.component';
import {DatatableModule} from "../shared/components/datatable/datatable.module";
import {ActivityLogsComponent} from "./activity-logs/activity-logs.component";
import {AgGridModule} from "ag-grid-angular";
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DateRangepickerModule } from '../shared/components/date-range-selector/date-range-selector.module';


@NgModule({
  declarations: [
    LayoutComponent,
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    MembersComponent,
    UnverifiedMembersComponent,
    ActivityLogsComponent
  ],
  imports: [
    AgGridModule,
    CommonModule,
    DatatableModule,
    DateRangepickerModule,
    AdminRoutingModule,
    FormsModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbCollapseModule,
    NgbAccordionModule ,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgbDropdownModule,
    NgbAlertModule
  ]

})
export class AdminModule {
}
