import {Component} from '@angular/core';
import {ActivityLogsService} from "../../shared/services/activity-logs.service";
import {ActivatedRoute} from "@angular/router";
import {ActivityLogs, ActivityLogsData, Changes, fieldsMapping} from '../../shared/models/activity-log.model';
import Swal from "sweetalert2";
import {formatDateWithTime} from "../../helpers/my_helper";
import {dropDownWorkplace} from "../../shared/models/static-data.model";

@Component({
  selector: 'activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrl: './activity-logs.component.scss'
})


export class ActivityLogsComponent {

  public id!: number;

  fromDate: string = '';
  toDate: string = '';

  public fieldsMapping = fieldsMapping

  formatDateWithTime = formatDateWithTime

  public activityLogsData: ActivityLogsData = {
    user_id: 0,
    email: '',
    name: '',
    last_name: '',
    id: 0,
    activity_logs: {}
  };

  constructor(private activityLogsService: ActivityLogsService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => {
      this.id = +params['id'];
      this.loadActivityLogs();
    });
  }


  loadActivityLogs(): void {
    if (this.id !== null) {
      this.activityLogsService.getActivityLogs(this.id).subscribe(result => {
        if (result.data) {
          this.transformActivityLogs(result.data)
        }
      });
    } else {
      console.error('ID is not defined');
    }
  }

  private transformActivityLogs(data: any): void {
    const transformedActivityLogs: ActivityLogs = {};

    data.activity_logs.forEach((log: any) => {
      const dateKey = log.created_at.split('T')[0];

      if (!transformedActivityLogs[dateKey]) {
        transformedActivityLogs[dateKey] = [];
      }

      transformedActivityLogs[dateKey].push(log);
    });

    const sortedActivityLogs = this.sortActivityLogsByTime(transformedActivityLogs);

    this.activityLogsData = {...data, activity_logs: sortedActivityLogs};

  }

  private sortActivityLogsByTime(logs: ActivityLogs): ActivityLogs {
    const sortedActivityLogs: ActivityLogs = {};

    // Sort logs within each date by time in descending order
    Object.entries(logs).forEach(([date, logsArray]) => {
      sortedActivityLogs[date] = logsArray.sort((logA: any, logB: any) => {
        return new Date(logB.created_at).getTime() - new Date(logA.created_at).getTime();
      });
    });

    return sortedActivityLogs;
  }


  searchActivityLogs() {
    if (!this.fromDate || !this.toDate) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please provide both start and end dates",
      });
      return;
    }
    const params = `?from=${this.fromDate}&to=${this.toDate}`
    this.activityLogsService.getActivityLogs(this.id, params).subscribe(result => {
      if (result.data) {
        this.transformActivityLogs(result.data)
      } else {
        this.activityLogsData.activity_logs = {}
      }
    });
  }

  parseChanges(changes: any): Changes {
    if (typeof changes === "string") {
      try {
        return JSON.parse(changes) as Changes; // Cast to Changes type
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return {}; // Return an empty object on error
      }
    }
    return changes;
  }

  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  getCurrentDate() {
    const today = new Date();
    return today.toISOString().split('T')[0];
  }

  getWorkplaceValue(key: any): string {
    const found = dropDownWorkplace.find(option => option.key === key);
    return found ? found.value : 'NULL';
  }

}
