import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {LayoutComponent} from "./layout/layout.component";
import {AuthGuard} from "../shared/guard/auth.guard";
import {MembersComponent} from "./members/members.component";
import {UnverifiedMembersComponent} from "./unverified-members/unverified-members.component";
import {ActivityLogsComponent} from "./activity-logs/activity-logs.component";

const routes: Routes = [{
  path: 'admin', component: LayoutComponent,
  canActivate: [AuthGuard],
  children: [
    {path: 'dashboard', component: DashboardComponent},
    {path: 'members', component: MembersComponent},
    {path: 'unverified/members', component: UnverifiedMembersComponent},
    {path: 'activity-logs/:id', component: ActivityLogsComponent },
  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
