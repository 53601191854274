<div class="card">

  <div class="row mb-md-3 mb-2">
    <div class="col-xl-4 col-lg-3">
      <h3 class="card-title mb-0">Subscription report</h3>
    </div>

    <div class="col-xl-8 col-lg-9 mt-md-0 mt-3 ">
      <div class="row g-3 align-items-center">


        <div class="col-md-4">
        </div>

        <div class="col-md-4">

        </div>

        <div class="col-md-4">
          <div class="d-flex align-items-end justify-content-end">
            <div class="w-100">

              <select class="form-select form-select-sm" [(ngModel)]="subscription"
              aria-label="Floating label select example">

              <option value="" selected>All subscriptions</option>
              <optgroup *ngFor="let group of subscriptionOptions" [label]="group.label">
                <option *ngFor="let option of group.options" [value]="option.key">
                  {{ option.value }}
                </option>
              </optgroup>

            </select>

            </div>
            <button class="btn btn-sm btn-primary icon-btn ms-3" (click)="searchSubscriptionStats()"><span class="text">Search</span></button>
          </div>

        </div>

      </div>
    </div>
  </div>


  <Datatable [url]="url" [colDefs]="colDefs" [jsonFilters]="jsonFilters" (onClearFilters)="handleClearFilters($event)"></Datatable>
</div>
