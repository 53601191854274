import {Component} from '@angular/core';
import {DashboardService} from "../../shared/services/dashboard.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  constructor(private dashboardService: DashboardService) {
  }

  ngOnInit(): void {
    this.loadDashboard();
  }

  dashboardTilesData: any;

  loadDashboard() {
    this.dashboardService.getDashboardTiles().subscribe((response) => {
      this.dashboardTilesData = response.data;
    });
  }
}


