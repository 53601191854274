import { Component } from '@angular/core';
import {ColDef} from "ag-grid-community";
import { environment } from '../../../../environments/environment';
import { subscriptionsField } from '../../../helpers/my_helper';
import { dropDownEmailSubscription, dropDownJobSubscription, dropDownMarketingSubscription, dropDownPrintSubscription } from '../../../shared/models/static-data.model';

@Component({
  selector: 'app-subscription-stats',
  templateUrl: './subscription-stats.component.html',
  styleUrl: './subscription-stats.component.scss'
})
export class SubscriptionStatsComponent {

  url = environment.myAccountBaseUrl + `report/subscription/stats`;

  subscription_type = "weekly_vt_letter";
  subscription_status = 1;
  rcvs_number = "0";

  subscriptionOptions = [
    {
      label: 'Email subscriptions',
      options: dropDownEmailSubscription
    },
    {
      label: 'Jobs subscriptions',
      options: dropDownJobSubscription
    },
    {
      label: 'Marketing subscriptions',
      options: dropDownMarketingSubscription
    },
    {
      label: 'Print subscriptions',
      options: dropDownPrintSubscription
    }
  ]

  jsonFilters: any = {"weekly_vt_letter": 1, "rcvs_number": "0"}

  colDefs: ColDef[] = [
    {headerName: 'SSO ID', field: 'user_id', filter: true, floatingFilter: true},
    {headerName: 'Email', field: 'email', filter: true, floatingFilter: true, flex: 1},
    {headerName: 'First name', field: 'name', filter: true, floatingFilter: true},
    {headerName: 'Last name', field: 'last_name', filter: true, floatingFilter: true},
    {headerName: 'Vet Times Weekly Newsletter', field: 'weekly_vt_letter', floatingFilter: true, cellRenderer: (params: any) => {
      return params.value === 1 ? 'Subscribed' : params.value === 0 ? 'Not subscribed' : '';
    }},
    {headerName: 'Rcvs', field: 'rcvs', filter: false, floatingFilter: true},
  ];

  searchSubscriptionStats() {
    this.jsonFilters = {};

    this.colDefs[4] = {
      ...this.colDefs[4],
      headerName: subscriptionsField(this.subscription_type),
      field: this.subscription_type,
    };

    this.colDefs[5] = {
      ...this.colDefs[5],
      filter: this.rcvs_number != '0',
    };

    this.jsonFilters = {
      [this.subscription_type]: this.subscription_status,
    }

    if (this.rcvs_number) {
      this.jsonFilters.rcvs_number = this.rcvs_number;
    }
  }
}
