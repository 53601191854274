<section class="dashboard-panel mb-lg-4 mb-3">
  <div class="row g-2 gx-md-3 ">
    <div class="col-lg-3 col-md-6" *ngFor="let row of dashboardTilesData">
      <div class="dashboard-card">
        <div class="content">
          <p class="text">{{row.label}}</p>
          <h4 class="value">{{row.value}}</h4>
        </div>
        <div class="icon-dashboard-card">
          <img src="../../../assets/images/{{row.icon}}.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</section>
<members></members>

