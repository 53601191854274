import { Component } from '@angular/core';
import { KpiReportingService } from '../../../shared/services/kpi-reporting.service';
import moment from 'moment';
import { Barchart } from '../../../shared/models/chart.model';
import { ChartService } from '../../../shared/services/chart.service';

@Component({
  selector: 'app-kpi-reporting',
  templateUrl: './kpi-reporting.component.html',
  styleUrl: './kpi-reporting.component.scss'
})
export class KpiReportingComponent {

  kpiReportingData: any;
  loggedInUser: any = 0;
  averageSession: any = 0;
  totalAccounts: any = 0;

  currentYear = moment().format('YYYY');
  lastYear = moment().subtract(1, 'years').format('YYYY');

  year: any = this.currentYear;

  date_from = moment().format('YYYY-MM-DD');
  date_to = moment().format('YYYY-MM-DD');

  public loggedInSessionsProps: Barchart = {
    element: "loggedInSessionsBarChart",
    data: []
  };

  public averageSessionsProps: Barchart = {
    element: "averageSessionsBarChart",
    data: []
  };

  public monthlyProps: Barchart = {
    element: "monthlyBarChart",
    data: []
  };

  constructor(private kpiReportingService: KpiReportingService, private chartService: ChartService) {}

  onDateRangeChanged(dateRange: { start: string, end: string }) {
    this.date_from = dateRange.start;
    this.date_to = dateRange.end;
    this.getKpiReporting();
  }

  onChangeYear(e: any) {
    this.year = e.target.value == 'this_year' ? this.currentYear : this.lastYear;
    this.getKpiReporting();
  }

  getBody() {
    return { range: { from: this.date_from, to: this.date_to }, year: this.year };
  }

  getKpiReporting() {
    this.kpiReportingService.kpiReporting(this.getBody())?.subscribe((res: any) => {

      this.kpiReportingData = res.kpireportingData
      this.totalAccounts = res.totalAccounts;
      this.loggedInUser = res.loggedInUser;
      this.averageSession = res.averageSession;

      this.loggedInSessionsProps = this.chartService.getChartProps("loggedInSessionsBarChart", res.loggedInSessionsBarChartData)
      this.averageSessionsProps = this.chartService.getChartProps("averageSessionsBarChart", res.averageSessionsBarChartData)
      this.monthlyProps = this.chartService.getChartProps("monthlyBarChart", res.barChartMonthlyData)
    })
  }
}
