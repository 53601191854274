import { Component } from '@angular/core';
import {ColDef} from "ag-grid-community";
import {formatDateWithTime} from '../../../helpers/my_helper';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-validate-account',
  templateUrl: './validate-account.component.html',
  styleUrl: './validate-account.component.scss'
})
export class ValidateAccountComponent {
  url = environment.myAccountBaseUrl + `report/validate/account`;

  validated_status = 1;
  jsonFilters: any = {"validated": 1};

  colDefs: ColDef[] = [
    {headerName: 'SSO ID', field: 'user_id', filter: true, floatingFilter: true},
    {headerName: 'First Name', field: 'name', filter: true, floatingFilter: true},
    {headerName: 'Last Name', field: 'last_name', filter: true, floatingFilter: true},
    {headerName: 'Email', field: 'email', filter: true, floatingFilter: true, flex: 1},
    {headerName: 'Country', field: 'country_of_residence', filter: true, floatingFilter: true},
    {headerName: 'Job Role', field: 'job_role', filter: true, floatingFilter: true},
    {headerName: 'Last validated', field: 'validated_at', valueFormatter: (params: any) => formatDateWithTime(params.value), floatingFilter: true, filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'inRange'],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          const cellDate = new Date(cellValue);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        }
      }},
  ];

  getData() {
    this.jsonFilters = {
      validated: this.validated_status
    }
  }
}
