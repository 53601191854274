import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from "./api.service";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root',
})

export class ActivityLogsService {


  constructor(private apiService: ApiService, private authService: AuthService) {
  }

  public header: any = {
    Authorization: this.authService.getToken,
  }

  getActivityLogs(id: number, params: string = ''): Observable<any> {
    return this.apiService.httpGetMyAccount(`activity-logs/${id}${params}`, this.header);
  }
}
