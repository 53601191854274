import {Component, ViewChild} from '@angular/core';
import {ColDef} from "ag-grid-community";
import {UserService} from "../../shared/services/user.service";
import {ButtonRendererComponent} from "../../shared/components/renderer/button-renderer.component";
import Swal from "sweetalert2";
import {DatatableComponent} from "../../shared/components/datatable/datatable.component";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-unverified-members',
  templateUrl: './unverified-members.component.html',
  styleUrl: './unverified-members.component.scss'
})
export class UnverifiedMembersComponent {

  @ViewChild(DatatableComponent) datatable!: DatatableComponent;

  constructor(public userService: UserService) {
  }

  url = environment.myAccountBaseUrl + `user/get-unverified-users`;

  colDefs: ColDef[] = [
    {headerName: 'First Name', field: 'name', filter: true, floatingFilter: true},
    {headerName: 'Last Name', field: 'last_name', filter: true, floatingFilter: true},
    {headerName: 'Email', field: 'email', filter: true, floatingFilter: true, flex: 1},
    {headerName: 'Country', field: 'country_of_residence', filter: true, floatingFilter: true},
    {headerName: 'Job Role', field: 'job_role', filter: true, floatingFilter: true},
    {
      headerName: 'Actions',
      cellRenderer: ButtonRendererComponent,
      cellRendererParams: {
        onClick: this.handleVerify.bind(this),
        label: 'Verify',
      },
    },
  ];

  handleVerify(data: any): void {
    Swal.fire({
      title: "Are you sure?",
      html: 'You want to verify this user.<br><br><br><strong>Note:</strong> Once user is verified, it can never be reverted.',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.verifyUser(data.rowData.id).subscribe(
          (response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Verified!",
                text: "The user has been verified",
                icon: "success"
              });
              this.datatable.refreshData();
            } else {
              Swal.fire({
                title: "Error!",
                text: response.errors,
                icon: "error"
              });
            }
          },
          (error) => {
            console.error('Error fetching users', error);
          }
        )
      }
    });
  }


}
