<div class="card">

  <div class="d-md-flex justify-content-between mb-md-3 mb-2">
    <div>
      <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin/members" class="text-dark text-decoration-none cursor-pointer">Members</a></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">Logs</li>
        </ol>
      </nav>
      <h3 class="card-title mb-0">{{activityLogsData.name + ' ' + activityLogsData.last_name }}</h3>
    </div>
    <div class="">
      <label class="form-label">Select date range</label>
      <div class="d-flex align-items-center gap-3">
        <input type="date" class="form-control py-2" placeholder="select date" [(ngModel)]="fromDate" [max]="getCurrentDate()">
        <span class="d-inline-block ">To</span>
        <input type="date" class="form-control py-2" placeholder="select date" [(ngModel)]="toDate" [max]="getCurrentDate()">
        <button class="btn btn-sm btn-primary" (click)="searchActivityLogs()"><span class="">Search</span></button>
      </div>
    </div>
  </div>

  <div class="log-wrap">
    <div *ngIf="activityLogsData?.activity_logs && isObjectNotEmpty(activityLogsData.activity_logs); else noData">
      <div ngbAccordion>
        <div *ngFor="let row of activityLogsData.activity_logs | keyvalue" ngbAccordionItem [collapsed]="true">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>{{ row.key }}</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="log-list">
                  <div *ngFor="let log of row.value" class="log-list-item">
                    <div class="time-indicator">
                      <div class="time-item">{{log.created_at | date: 'hh:mm:ss a' }}</div>
                    </div>
                    <div class="row w-100">
                      <div class="col-lg-6">
                        <h5>Old</h5>
                        <div class="values-card old">
                          <div class="row">
                            <div class="col-md-4">
                              <h6>Field</h6>
                            </div>
                            <div class="col-md-8">
                              <h6>Value</h6>
                            </div>
                          </div>

                          <div class="row" *ngFor="let change of parseChanges(log.changes) | keyvalue ">
                            <div class="col-md-4">
                              <p class="text mb-1">{{fieldsMapping[change.key] || change.key}}</p>
                            </div>
                            <div class="col-md-8">
                              <p class="text mb-1">
                                {{
                                change.key === 'validated_at'
                                  ? formatDateWithTime(change.value.old)
                                  : change.key === 'workplace'
                                    ? getWorkplaceValue(change.value.old)
                                    : (change.value.old ? change.value.old : 'NULL')
                                }}
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <h5>New</h5>
                        <div class="values-card new">
                          <div class="row">
                            <div class="col-md-4">
                              <h6>Field</h6>
                            </div>
                            <div class="col-md-8">
                              <h6>Value</h6>
                            </div>
                          </div>
                          <!-- loop row  -->

                          <div class="row" *ngFor="let change of parseChanges(log.changes) | keyvalue ">
                            <div class="col-md-4">
                              <p class="text mb-1">{{fieldsMapping[change.key] || change.key }}</p>
                            </div>
                            <div class="col-md-8">
                              <p class="text mb-1">
                                {{
                                change.key === 'validated_at' ?
                                  (formatDateWithTime(change.value.new))
                                  : change.key === 'workplace'
                                    ? getWorkplaceValue(change.value.new)
                                    : change.value.new
                                }}
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noData>
      <ngb-alert type="danger">No activity found</ngb-alert>
    </ng-template>
  </div>
</div>
