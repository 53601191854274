import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';

import { ReportsRoutingModule } from './reports-routing.module';
import { SubscriptionComponent } from './subscription/subscription.component';
import {DatatableModule} from "../../shared/components/datatable/datatable.module";
import { SubscriptionStatsComponent } from './subscription-stats/subscription-stats.component';
import { ValidateAccountComponent } from './validate-account/validate-account.component';
import { UserAccessLogsComponent } from './user-access-logs/user-access-logs.component';
import { KpiReportingComponent } from './kpi-reporting/kpi-reporting.component';
import { DateRangepickerModule } from '../../shared/components/date-range-selector/date-range-selector.module';
import { PieChartModule } from '../../shared/charts/pie-chart/pie-chart.module';


@NgModule({
  declarations: [
    SubscriptionComponent,
    SubscriptionStatsComponent,
    ValidateAccountComponent,
    UserAccessLogsComponent,
    KpiReportingComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReportsRoutingModule,
    DatatableModule,
    DateRangepickerModule,
    FormsModule,
    PieChartModule
]
})
export class ReportsModule { }
