import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatatableComponent} from "./datatable.component";
import {AgGridModule} from "ag-grid-angular";


@NgModule({
  declarations: [
    DatatableComponent
  ],
  imports: [
    CommonModule,
    AgGridModule
  ],
  exports: [
    DatatableComponent
  ],

})
export class DatatableModule {
}
