<nav class='sidebar' [ngClass]="{'sidebar-menu-expanded': menu, 'sidebar-menu-collapsed': !menu}">
  <!-- sidebar-menu-collapsed -->
  <a href="" id="close-menu" (click)="handleClick($event)"><img src="../../../../assets/images/close-icon.svg" alt=""> </a>
  <ul>

    <li [ngClass]="{'active': currentRoute === '/admin/dashboard'}">
      <a class='expandable' href='' data-bs-toggle="tooltip" (mouseleave)="dashboardTooltip.close()" #dashboardTooltip="ngbTooltip" placement="end" ngbTooltip="Dashboard"
         routerLink="/admin/dashboard">
        <span class="nav-icon"><img src="../../../../assets/images/icon-dashboard.svg" alt=""></span>
        <span class='expanded-element'>Dashboard</span>
      </a>
    </li>

    <li [ngClass]="{'active': currentRoute === '/admin/members'}">
      <a class='expandable' href='' role="button" data-bs-toggle="tooltip" (mouseleave)="membersTooltip.close()" #membersTooltip="ngbTooltip" placement="end" ngbTooltip="Members"
         routerLink="/admin/members">
        <span class="nav-icon"><img src="../../../../assets/images/icon-user.svg" alt=""></span>
        <span class='expanded-element'>Members</span>
      </a>
    </li>

    <li [ngClass]="{'active': currentRoute === '/admin/unverified/members'}">
      <a class='expandable' href='' data-bs-toggle="tooltip" (mouseleave)="unverifiedTooltip.close()" #unverifiedTooltip="ngbTooltip" placement="end" ngbTooltip="Unverified Members"
         routerLink="/admin/unverified/members">
        <span class="nav-icon"><img src="../../../../assets/images/unverified-user.svg" alt=""></span>
        <span class='expanded-element'>Unverified Members</span>
      </a>
    </li>

    <li>

      <a class='expandable collapse-menu' (click)="collapse.toggle()"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample" data-bs-toggle="tooltip" (mouseleave)="subscriptionReportTooltip.close()" #subscriptionReportTooltip="ngbTooltip" placement="end" ngbTooltip="Reports"
         >
        <span class="nav-icon"><img src="../../../../assets/images/reports.svg" alt=""></span>
        <span class='expanded-element'>Reports</span>
      </a>

    </li>
    <ul class="sub-menu" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" >

      <li [ngClass]="{'active': currentRoute === '/admin/report/subscription'}">
        <a class='expandable' routerLink="/admin/report/subscription">
          <span class="nav-icon"><img src="../../../../assets/images/subscription.svg" alt=""></span>
          <span class='expanded-element'>Subscription</span>
        </a>
      </li>

      <li [ngClass]="{'active': currentRoute === '/admin/report/subscription/stats'}">
        <a class='expandable' routerLink="/admin/report/subscription/stats">
          <span class="nav-icon"><img src="../../../../assets/images/subscription.svg" alt=""></span>
          <span class='expanded-element'>Subscription Stats</span>
        </a>
      </li>

      <li [ngClass]="{'active': currentRoute === '/admin/report/validate/account'}">
        <a class='expandable' routerLink="/admin/report/validate/account">
          <span class="nav-icon"><img src="../../../../assets/images/subscription.svg" alt=""></span>
          <span class='expanded-element'>Validated Accounts</span>
        </a>
      </li>

      <li [ngClass]="{'active': currentRoute === '/admin/report/user-access-logs'}">
        <a class='expandable' routerLink="/admin/report/user-access-logs">
          <span class="nav-icon"><img src="../../../../assets/images/subscription.svg" alt=""></span>
          <span class='expanded-element'>User Access Logs</span>
        </a>
      </li>

      <li [ngClass]="{'active': currentRoute === '/admin/report/kpi-reporting'}">
        <a class='expandable' routerLink="/admin/report/kpi-reporting">
          <span class="nav-icon"><img src="../../../../assets/images/subscription.svg" alt=""></span>
          <span class='expanded-element'>KPI Reporting</span>
        </a>
      </li>

    </ul>

  </ul>

</nav>
