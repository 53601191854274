import { Component } from '@angular/core';
import { Router, NavigationEnd  } from '@angular/router';
import { EmitterService } from '../../../shared/services/event-service.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',

})
export class SidebarComponent {
  public menu: boolean = true;
  currentRoute: string = this.router.url;
  activeLink: string = 'dashboard';
  isCollapsed = true;


  constructor (private eventEmitter: EmitterService, private router: Router) {}
  ngOnInit () {

    this.eventEmitter.listen('sidebar', (menu: any) => {
      this.menu = menu;
    })

    // Get the current route URL
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
      }
    });
  }

  handleClick(e: any) {
    e.preventDefault();
    this.menu = !this.menu
    this.eventEmitter.emit('sidebar', this.menu)
  }
}
