import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidator} from "../validators/form.validator";

export class ProfessionalDetailsValidation {

  static createProfessionalDetailsForm(fb: FormBuilder): FormGroup {
    const currentYear = new Date().getFullYear();
    const maxYear = currentYear + 10
    return fb.group({
      workplace: ['', FormValidator.requiredField('Workplace is required')],
      work_address: ['', FormValidator.requiredField('Work address is required')],
      university: ['', FormValidator.requiredField('University is required')],
      uni_country: ['', FormValidator.requiredField('University country is required.')],
      year_qualification: ['', [FormValidator.requiredField('Year of qualification is required'), Validators.min(currentYear), Validators.max(maxYear)]]
    });
  }

  static updateValidators(job_role: string, form: any) {
    const controls = ['year_qualification', 'uni_country', 'university'].map(field => form.get(field));
    const [yearQualificationControl, uniCountryControl, countryControl] = controls;

    const currentYear = new Date().getFullYear();
    const maxYear = currentYear + 10;

    const isStudentOrGraduate = job_role === 'S' || job_role === 'G';
    const validators = isStudentOrGraduate ? [Validators.required] : [];

    yearQualificationControl.setValidators(
      isStudentOrGraduate ? [Validators.required, Validators.min(currentYear), Validators.max(maxYear)] : []
    );

    [uniCountryControl, countryControl].forEach(control => control.setValidators(validators));

    controls.forEach(control => control.updateValueAndValidity());

    return isStudentOrGraduate;
  }

  static updateWorkAddressValidators(value: string, form: any): void {
    const workAddressControl = form.get('work_address');
    workAddressControl?.setValidators(value === 'lo' ? [] : [Validators.required]);
    workAddressControl?.updateValueAndValidity();
  }
}
