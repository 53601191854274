<header>
  <div class="header-container">
 <div class="d-flex align-items-center">
  <div class="logo-parent">
      <a href='#' id='justify-icon' (click)="handleClick($event)">
          <span class='menu-icon'><img src="../../../../assets/images/menu-icon.svg" alt=""></span>
        </a>
      <a href="#"> <img class="logo-img"  src="assets/images/logo.png" alt=""></a>

   </div>

 </div>

      <div class="profile-container">

          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-profile dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle>
              <span class="profile-image"><img src="../../../assets/images/user-prof.svg" alt=""></span>
              {{name}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem>Setting</button>
              <button ngbDropdownItem (click)="logout()">Log out</button>
            </div>
          </div>



          <!-- <div class="dropdown">
              <button class="btn btn-profile dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {{name}}
                <span class="profile-image"><img src="../../../../assets/images/user-prof.svg" alt=""></span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item" href="#">Setting</a></li>
                <li><a class="dropdown-item" href="#">Log out</a></li>
              </ul>
            </div> -->
      </div>

  </div>


</header>
