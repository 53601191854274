import {NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {CommonModule} from '@angular/common';

import { DateRangeSelectorComponent } from './date-range-selector.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [
    DateRangeSelectorComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [DateRangeSelectorComponent],

})
export class DateRangepickerModule {
}
