import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EmailSubscriptionComponent} from "./email-subscription/email-subscription.component";
import {EmailSubscriptionSettingComponent} from "./email-subscription-setting/email-subscription-setting.component";
import {ProfessionalDetailsComponent} from "./professional-details/professional-details.component";
import { AuthGuard } from '../shared/guard/auth.guard';

const routes: Routes = [
  {path: 'subscription/email', component: EmailSubscriptionComponent, canActivate: [AuthGuard] },
  {path: 'subscription/setting', component: EmailSubscriptionSettingComponent, canActivate: [AuthGuard] },
  {path: 'professional-details', component: ProfessionalDetailsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriptionsRoutingModule {
}
