import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidator {
  static requiredField(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      return control.value ? null : {required: message};
    };
  }

  static alphaNumeric(): ValidatorFn {
    return (control: AbstractControl) => {
      const regex = /^[a-zA-Z0-9 ]*$/;
      return regex.test(control.value) ? null : {invalidAlphaNumeric: true};
    };
  }
}
