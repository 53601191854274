import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SubscriptionComponent} from "./subscription/subscription.component";
import {AuthGuard} from "../../shared/guard/auth.guard";
import {LayoutComponent} from "../layout/layout.component";
import { SubscriptionStatsComponent } from './subscription-stats/subscription-stats.component';
import { ValidateAccountComponent } from './validate-account/validate-account.component';
import {UserAccessLogsComponent} from "./user-access-logs/user-access-logs.component";
import { KpiReportingComponent } from './kpi-reporting/kpi-reporting.component';

const routes: Routes = [{
  path: 'admin/report', component: LayoutComponent,
  canActivate: [AuthGuard],
  children: [
    {path: 'subscription', component: SubscriptionComponent},
    {path: 'subscription/stats', component: SubscriptionStatsComponent},
    {path: 'validate/account', component: ValidateAccountComponent},
    {path: 'user-access-logs', component: UserAccessLogsComponent},
    {path: 'kpi-reporting', component: KpiReportingComponent}
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule {
}
