import {Component} from '@angular/core';
import {ColDef} from "ag-grid-community";
import {formatDateWithTime} from "../../../helpers/my_helper";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-user-access-logs',
  templateUrl: './user-access-logs.component.html',
  styleUrl: './user-access-logs.component.scss'
})
export class UserAccessLogsComponent {

  url = environment.myAccountBaseUrl + "user-access-logs"

  colDefs: ColDef[] = [
    {headerName: 'Email', field: 'user_profile.email', filter: true, floatingFilter: true, flex: 1},
    {headerName: 'First Name', field: 'user_profile.name', filter: true, floatingFilter: true},
    {headerName: 'Last Name', field: 'user_profile.last_name', filter: true, floatingFilter: true},
    {headerName: 'Country', field: 'user_profile.country_of_residence', filter: true, floatingFilter: true},
    {headerName: 'Job Role', field: 'user_profile.job_role', filter: true, floatingFilter: true},
    {
      headerName: 'Date', field: 'created_at', valueFormatter: (params: any) => formatDateWithTime(params.value), floatingFilter: true, filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'inRange'],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          const cellDate = new Date(cellValue);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    },
    {headerName: 'Website', field: 'platform.platform_name', filter: true, floatingFilter: true},
  ];
}
