import { Component } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "../../shared/services/api.service";
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';


@Component({
  selector: 'app-email-subscription-setting',
  templateUrl: './email-subscription-setting.component.html',
  styleUrl: './email-subscription-setting.component.scss'
})
export class EmailSubscriptionSettingComponent {

  token = this.cookieService.get('sso_signed');
  loader: boolean = false;

  job_subscription: any = {
    weekly_job_letter: false,
    monthly_advice: false,
    subscribe_to_vbd_offers: false,
    subscribe_to_third_party_info: false,
    subscribe_to_workplace_panel: false
  };

  constructor(private apiService: ApiService, private spinner: NgxSpinnerService, private cookieService: CookieService, public router: Router, private googleAnalytics: GoogleAnalyticsService) {
    this.getJobSubscription();
  }

  getJobSubscription() {
    if (this.token) {

      let headers = {
        Authorization: this.token
      }

      this.apiService.httpGetMyAccount('user/get-user', headers).subscribe((user: any) => {
        this.job_subscription.weekly_job_letter = user.data.weekly_job_letter;
        this.job_subscription.monthly_advice = user.data.monthly_advice;
        this.job_subscription.subscribe_to_vbd_offers = user.data.subscribe_to_vbd_offers;
        this.job_subscription.subscribe_to_third_party_info = user.data.subscribe_to_third_party_info;
        this.job_subscription.subscribe_to_workplace_panel = user.data.subscribe_to_workplace_panel;
      })
    }
  }

  trackButtonClick(btn_name: any) {
    this.googleAnalytics.trackButtonClick({'button': btn_name, page: 'Email subscription setting'});
  }

  trackSubscription(event: any, json: any) {
    json.status = event.target.checked ? 'Subscribe' : 'Unsubscribe'
    this.googleAnalytics.trackButtonClick(json);
  }

  handleOnChange(event: Event){
    const inputElement = event.target as HTMLInputElement;
    const name = inputElement.value;
    const checked = inputElement.checked;
    this.job_subscription[name] = checked ? 1 : 0;
  }

  saveJobSubscriptions(event: Event) {
    event.preventDefault();

    if (this.token) {
      this.spinner.show();
      this.loader = true;
      let headers = {
        Authorization: this.token
      }
      this.apiService.httpPostMyAccount('user/update-user', this.job_subscription, headers).subscribe((res: any) => {
        this.spinner.hide();
        this.loader = false;
        this.router.navigate(['/professional-details']);
      }, error => {
        this.spinner.hide();
        this.loader = false;
      });
    }
  }

  productLink() {
    window.open(environment.myJobsUrl);
  }
}
